import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import whiteIcon from "../assets/white_icon.svg";

export const RequestQuote = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    projectType: "",
    projectDescription: "",
  });

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    isError: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the scroll height
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://us-central1-elias-construcciones.cloudfunctions.net/api/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setModalContent({
          title: "Se ha enviado correctamente tu mensaje",
          message:
            "Nos pondremos en contacto contigo lo antes posible. Gracias por tu confianza!",
          isError: false,
        });
      } else {
        setModalContent({
          title: "Ha habido un error en el envio de tu mensaje",
          message:
            "Por favor, vuelve a intentarlo en otro momento. Sentimos los inconvenientes!",
          isError: true,
        });
      }
    } catch (error) {
      console.error("Error al enviar el email:", error);
      setModalContent({
        title: "Ha habido un error en el envio de tu mensaje",
        message:
          "Por favor, vuelve a intentarlo en otro momento. Sentimos los inconvenientes!",
        isError: true,
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      email: "",
      projectType: "",
      projectDescription: "",
    });
  };

  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="requestaquote"
    >
      <div className="bg-accent rounded-3xl p-8">
        <div className="grid grid-cols-12 gap-4 pb-8">
          <div className="col-span-12 md:col-span-5 flex flex-col justify-between">
            <h2 className="text-3xl font-light text-white pb-10">
              Solicita un Presupuesto
            </h2>
            <div className="flex justify-start items-end">
              <img src={whiteIcon} alt="Icono de Usuario" className="w-6 h-6" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-7 text-white font-extralight">
            <div className="w-full">
              <p className="mb-4 font-extralight">
                ¿Interesado en obtener un presupuesto para tu proyecto? Completa
                el formulario a continuación y nos pondremos en contacto contigo
                lo antes posible. Cuanta más información proporciones sobre tu
                proyecto, mejor podremos entender tus necesidades y brindarte un
                presupuesto preciso y detallado.
              </p>
              <form className="w-full" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mb-5 bg-accent border border-custom-gray-100 text-custom-gray-200 text-sm rounded-3xl block w-full p-2.5"
                  placeholder="Nombre completo"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mb-5 bg-accent border border-custom-gray-100 text-custom-gray-200 text-sm rounded-3xl block w-full p-2.5"
                  placeholder="Email"
                  required
                />
                <input
                  type="text"
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleChange}
                  className="mb-5 bg-accent border border-custom-gray-100 text-custom-gray-200 text-sm rounded-3xl block w-full p-2.5"
                  placeholder="Tipo de proyecto"
                  required
                />
                <textarea
                  name="projectDescription"
                  value={formData.projectDescription}
                  onChange={handleChange}
                  className="mb-5 bg-accent border border-custom-gray-100 text-custom-gray-200 text-sm rounded-3xl block w-full p-2.5 resize-none overflow-hidden"
                  placeholder="Descripción del proyecto"
                  required
                />
                <button
                  type="submit"
                  className="bg-white text-accent font-normal py-2 px-4 rounded-3xl w-full"
                >
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Transition show={open}>
        <Dialog className="relative z-10 " onClose={() => {}}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 max-w-xs md:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div
                        className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                          modalContent.isError ? "bg-red-100" : "bg-green-100"
                        } sm:mx-0 sm:h-10 sm:w-10`}
                      >
                        {modalContent.isError ? (
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {modalContent.title}
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {modalContent.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-light sm:ml-3 sm:w-auto"
                      onClick={handleClose}
                    >
                      Cerrar
                    </button>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  );
};

export default RequestQuote;
