import React, { useState } from "react";

const AccordionItem = ({ title, content, isActive, onClick, id }) => {
  return (
    <div
      className={`hs-accordion ${
        isActive ? "active" : ""
      } bg-white -mt-px first:rounded-t-lg last:rounded-b-lg`}
      id={id}
    >
      <button
        className="hs-accordion-toggle hs-accordion-active:text-blue-600 flex justify-between items-center w-full font-normal text-start text-accent py-2 disabled:opacity-50 disabled:pointer-events-none"
        aria-controls={id + "-content"}
        onClick={onClick}
      >
        <span>{title}</span>
        <svg
          className={`w-3 h-3 transform transition-transform ${
            isActive ? "" : "rotate-180"
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
      <div
        id={id + "-content"}
        className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 font-extralight ${
          isActive ? "" : "hidden"
        }`}
        aria-labelledby={id}
      >
        <div className="pb-2">
          <p className="text-gray-800">{content}</p>
        </div>
      </div>
    </div>
  );
};

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const items = [
    {
      title: "Renovación del Espacio del Hoga",
      content:
        "Transformamos espacios existentes con nuestras soluciones de renovación personalizadas. Desde el primer contacto hasta la finalización del proyecto, nos comprometemos a proporcionar un servicio al cliente excepcional, escuchando cuidadosamente sus necesidades y manteniéndolos informados en cada etapa del proceso. Con un enfoque en calidad y transparencia, nuestros clientes confían en nosotros para ofrecer resultados sobresalientes sin sorpresas de último minuto.",
      id: "hs-bordered-heading-one",
    },
    {
      title: "Renovación de Espacios de Apartamentos",
      content:
        "Transformamos espacios existentes con nuestras soluciones de renovación personalizadas. Desde el primer contacto hasta la finalización del proyecto, nos comprometemos a proporcionar un servicio al cliente excepcional, escuchando cuidadosamente sus necesidades y manteniéndolos informados en cada etapa del proceso. Con un enfoque en calidad y transparencia, nuestros clientes confían en nosotros para ofrecer resultados sobresalientes sin sorpresas de último minuto.",
      id: "hs-bordered-heading-two",
    },
    {
      title: "Renovación de Espacios Comerciales",
      content:
        "Transformamos espacios existentes con nuestras soluciones de renovación personalizadas. Desde el primer contacto hasta la finalización del proyecto, nos comprometemos a brindar un servicio al cliente excepcional, escuchando cuidadosamente sus necesidades y manteniéndolos informados en cada etapa del proceso. Con un enfoque en calidad y transparencia, nuestros clientes confían en nosotros para ofrecer resultados sobresalientes sin sorpresas de último minuto.",
      id: "hs-bordered-heading-three",
    },
  ];

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="hs-accordion-group">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <AccordionItem
            key={index}
            title={item.title}
            content={item.content}
            isActive={index === activeIndex}
            onClick={() => handleClick(index)}
            id={item.id}
          />
          {(index === 0 || index === 1) && (
            <hr className="border-gray-300 my-4" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Accordion;
