import React from "react";
import whiteIcon from "../assets/white_icon.svg";
import photoJumbo from "../assets/photo_jumbo.png";

const BuildingDemolition = () => {
  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="buildingdemolition"
    >
      <div className="bg-accent rounded-3xl p-8">
        <div className="grid grid-cols-12 gap-4 pb-8">
          <div className="col-span-12 md:col-span-4 flex flex-col justify-between">
            <h2 className="text-3xl font-light text-white pb-10">
              Demolición de Edificios
            </h2>
            <div className="flex justify-start items-end">
              <img src={whiteIcon} alt="Icono de Usuario" className="w-6 h-6" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-8 text-white font-extralight flex items-center">
            Contamos con la experiencia y los recursos necesarios para llevar a
            cabo trabajos de demolición de edificios, tanto en interiores como
            en exteriores. Nuestro compromiso con la seguridad y la eficiencia
            se refleja en cada proyecto, donde nos esforzamos por completar el
            trabajo de manera puntual y sin contratiempos. Con nosotros, los
            clientes pueden estar seguros de que recibirán un servicio confiable
            y serio, sin sorpresas desagradables en el camino.
          </div>
        </div>
        <div>
          <img
            src={photoJumbo}
            alt="Icono de Usuario"
            className="rounded-3xl"
          />
        </div>
      </div>
    </section>
  );
};

export default BuildingDemolition;
