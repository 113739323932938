import React from "react";
import whiteIcon from "../assets/white_icon.svg";
import decoServSmall from "../assets/deco_services_small.png";
import decoServBig from "../assets/deco_services_big.png";

const DecorationServices = () => {
  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="decorationservices"
    >
      <div className="bg-accent rounded-3xl p-8">
        <div className="grid grid-cols-12 gap-4 pb-8">
          <div className="col-span-12 md:col-span-4 flex flex-col justify-between">
            <h2 className="text-3xl font-light text-white pb-10">
              Servicios de Decoración
            </h2>
            <div className="flex justify-start items-end">
              <img src={whiteIcon} alt="Icono de Usuario" className="w-6 h-6" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-8 text-white font-extralight flex items-center">
            Nuestro compromiso con la excelencia en el servicio se extiende a
            nuestra división de decoración. Trabajamos estrechamente con
            nuestros clientes para comprender sus gustos y preferencias,
            ofreciendo recomendaciones expertas y soluciones creativas que
            transforman sus espacios en verdaderas expresiones de su estilo
            personal. Con un enfoque en el servicio al cliente y la integridad
            en cada paso del proceso, garantizamos una experiencia sin
            inconvenientes y resultados que superan las expectativas.
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-4 flex flex-col">
            <img
              src={decoServSmall}
              alt="Icono de Usuario"
              className="rounded-3xl h-full object-cover"
            />
          </div>
          <div className="col-span-12 md:col-span-8 flex flex-col">
            <img
              src={decoServBig}
              alt="Icono de Usuario"
              className="rounded-3xl h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DecorationServices;
