import React from "react";
import CallToAction from "./call-to-acction";
import Header from "./header";
import smallPhotoHeader from "../assets/photo_header_small.png";
import bigPhotoHeader from "../assets/photo_header_big.png";

const Hero = () => {
  return (
    <section className="hero sm:px-8 md:pt-6 pt-24 bg-white mx-auto p-4 max-w-7xl scroll-mt-24">
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-12 w-full gap-6 md:gap-12 items-center">
        <div className="col-span-1 md:col-span-5 flex flex-col space-y-6 h-full">
          <div className="w-full flex-none flex items-start justify-start">
            <CallToAction />
          </div>
          <div className="w-full flex-grow relative h-64 md:h-full">
            <img
              className="w-full h-full object-cover rounded-3xl"
              src={smallPhotoHeader}
              alt="Galería"
            />
          </div>
        </div>
        <div className="col-span-1 md:col-span-7 flex justify-center h-64 md:h-full">
          <img
            className="w-full h-full object-cover rounded-3xl"
            src={bigPhotoHeader}
            alt="Galería"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
