import "./App.css";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Services from "./components/services";
import BuildingConstructions from "./components/building-construction";
import BuildingDemolition from "./components/building-demolition";
import SpaceRenovation from "./components/space-renovation";
import PlasterboardInstallation from "./components/plasterboard-installation";
import DecorationServices from "./components/decoration-services";
import ContactUs from "./components/contact-us";
import RequestQuote from "./components/request-quote";

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <main className="md:pt-36">
        <Hero />
        <Services />
        <BuildingConstructions />
        <BuildingDemolition />
        <SpaceRenovation />
        <PlasterboardInstallation />
        <DecorationServices />
        <ContactUs />
        <RequestQuote />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
