import React from "react";
import Accordion from "./accordion" 
import accentIcon from "../assets/accent_icon.svg";
import spaceBig from "../assets/space_reno_big.png";
import spaceSmall from "../assets/space_reno_small.png";

const SpaceRenovation = () => {
  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="spacerenovation"
    >
      <div className="grid grid-cols-12 gap-4 pb-8">
        <div className="col-span-11 md:col-span-11 flex">
          <h2 className="text-3xl font-light text-accent pb-2">
            Renovación de Espacios
          </h2>
        </div>
        <div className="col-span-1 md:col-span-1 justify-end flex">
          <img src={accentIcon} alt="Icono de Usuario" className="w-6 h-6" />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 pb-8">
        <div className="col-span-8 md:col-span-4 flex items-center">
          <div className="relative w-full h-[359px] md:min-h-[479px]">
            <img
              src={spaceBig}
              alt="Icono de Usuario"
              className="rounded-3xl w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="col-span-4 md:col-span-2 flex items-center">
          <div className="relative w-full h-[359px] md:min-h-[479px]">
            <img
              src={spaceSmall}
              alt="Icono de Usuario"
              className="rounded-3xl w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="col-span-12 md:min-h-[359px] md:col-span-6 flex flex-col">
          <Accordion />
        </div>
      </div>
    </section>
  );
};

export default SpaceRenovation;
