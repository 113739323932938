import React from "react";
import serviceHome from "../assets/services_home.svg";
import serviceHomeX from "../assets/services_home_x.svg";
import serviceTruck from "../assets/services_truck.svg";
import serviceTool from "../assets/services_tool.svg";
import serviceBrush from "../assets/services_brush.svg";

const Services = () => {
  return (
    <section
      className="Services mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="services"
    >
      <h2 className="text-3xl font-light text-accent pb-10">
        Nuestros servicios
      </h2>
      <div className="grid grid-cols-12 gap-4">
        {/* Primera fila */}
        <a
          href="/#buildingconstructions"
          className="col-span-4 rounded-xl bg-white p-4 text-[0.8125rem] shadow-black/5 hover:bg-slate-50 ring-1 ring-slate-700/10 flex flex-col items-center justify-center"
        >
          <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2">
            <img
              src={serviceHome}
              alt="Construcción de Edificios"
              className="w-6 h-6"
            />
          </div>
          <p className="text-accent text-center">Construcción de Edificios</p>
        </a>
        <a
          href="/#buildingdemolition"
          className="col-span-4 rounded-xl bg-white p-4 text-[0.8125rem] shadow-black/5 hover:bg-slate-50 ring-1 ring-slate-700/10 flex flex-col items-center justify-center"
        >
          <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2">
            <img
              src={serviceTruck}
              alt="Demolición de Edificios"
              className="w-6 h-6"
            />
          </div>
          <p className="text-accent text-center">Demolición de Edificios</p>
        </a>
        <a
          href="/#spacerenovation"
          className="col-span-4 rounded-xl bg-white p-4 text-[0.8125rem] shadow-black/5 hover:bg-slate-50 ring-1 ring-slate-700/10 flex flex-col items-center justify-center"
        >
          <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2">
            <img
              src={serviceHomeX}
              alt="Renovación de Espacios"
              className="w-6 h-6"
            />
          </div>
          <p className="text-accent text-center">Renovación de Espacios</p>
        </a>
        {/* Segunda fila */}
        <a
          href="/#plasterboardinstallation"
          className="col-span-6 rounded-xl bg-white p-4 text-[0.8125rem] shadow-black/5 hover:bg-slate-50 ring-1 ring-slate-700/10 flex flex-col items-center justify-center"
        >
          <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2">
            <img
              src={serviceTool}
              alt="Instalación de Placas de Yeso"
              className="w-6 h-6"
            />
          </div>
          <p className="text-accent text-center">
            Instalación de Placas de Yeso
          </p>
        </a>
        <a
          href="/#decorationservices"
          className="col-span-6 rounded-xl bg-white p-4 text-[0.8125rem] shadow-black/5 hover:bg-slate-50 ring-1 ring-slate-700/10 flex flex-col items-center justify-center"
        >
          <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2">
            <img
              src={serviceBrush}
              alt="Servicios de decoración"
              className="w-6 h-6"
            />
          </div>
          <p className="text-accent text-center">Servicios de decoración</p>
        </a>
      </div>
    </section>
  );
};

export default Services;
