import React from "react";
import logo from "../assets/logo.svg";

const Footer = () => {
  return (
    <div className="flex flex-col pt-24 items-center justify-center text-center text-accent font-extralight bg-white">
      {/* Logo */}
      <div className="mb-6">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <span className="self-center whitespace-nowrap">
            <img
              className="inset-0 h-12 w-12 object-cover rounded-3xl"
              src={logo}
              alt="gallery"
            />
          </span>
        </a>
      </div>

      <div className="container mx-auto p-4">
        {/* Links */}
        <div className="flex justify-center mb-6">
          <div className="grid grid-cols-12 md:grid-cols-5 gap-4">
            <div className="col-span-4 md:col-span-1">
              <a href="#buildingconstructions" className="hover:text-blue-500">
                Construcción
              </a>
            </div>
            <div className="col-span-4 md:col-span-1">
              <a href="#buildingdemolition" className="hover:text-blue-500">
                Demolición
              </a>
            </div>
            <div className="col-span-4 md:col-span-1">
              <a href="#spacerenovation" className="hover:text-blue-500">
                Renovación
              </a>
            </div>
            <div className="col-span-6 md:col-span-1">
              <a
                href="#plasterboardinstallation"
                className="hover:text-blue-500"
              >
                Placa de yeso
              </a>
            </div>
            <div className="col-span-6 md:col-span-1">
              <a href="#decorationservices" className="hover:text-blue-500">
                Decoración
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4">
        {/* Contact Information */}
        <div className="flex flex-col justify-center items-center mb-12">
          <div className="flex items-center flex-wrap justify-center">
            <p className="mr-4 mb-4 md:mb-0">+ 34 643 75 00 27</p>
            <span className="hidden md:inline border-l border-gray-300 h-6 mx-4"></span>
            <p className="mr-4 mb-4 md:mb-0">iliassrubio1@gmail.com</p>
            <span className="hidden md:inline border-l border-gray-300 h-6 mx-4"></span>
            <p className="mb-4 md:mb-0">+ 34 643 75 00 27</p>
          </div>
        </div>
      </div>

      {/* Full-width Divider */}
      <div className="w-full border-t border-gray-300 mb-6"></div>

      {/* Footer Text */}
      <div className="mb-6">
        <p>© 2024 Constructora Elias. Todos los derechos reservados.</p>
      </div>
    </div>
  );
};

export default Footer;
