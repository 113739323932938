import React from "react";
import accentIcon from "../assets/accent_icon.svg";
import accentWhiteIcon from "../assets/accent_white_icon.svg";
import plasterPhoto from "../assets/plaster_photo.png";

const PlasterboardInstallation = () => {
  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="plasterboardinstallation"
    >
      <h2 className="text-3xl font-light text-accent pb-6">
        Instalación de Placas de Yeso
      </h2>
      <div className="grid grid-cols-12 gap-4 pb-8">
        <div className="col-span-12 md:col-span-2 flex"></div>
        <div className="col-span-12 md:col-span-10 font-extralight flex">
          Nuestra empresa se especializa en la construcción de edificaciones,
          ofreciendo un servicio integral que abarca desde la planificación
          hasta la ejecución de proyectos residenciales, comerciales e
          industriales. Nos comprometemos a brindar un servicio al cliente
          excepcional en cada paso del proceso, manteniendo una comunicación
          clara y transparente para garantizar la plena satisfacción de nuestros
          clientes. Con un enfoque en la seriedad e integridad, nuestros
          clientes confían en nosotros para llevar a cabo sus proyectos con
          profesionalismo y sin sorpresas de última hora.
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 pb-8">
        <div className="col-span-12 md:col-span-8 flex">
          <img
            src={plasterPhoto}
            alt="Icono de Usuario"
            className="rounded-3xl w-full h-full object-cover"
          />
        </div>
        <div className="hidden md:flex col-span-12 md:col-span-2 justify-end">
          <img src={accentIcon} alt="Icono de Usuario" className="w-6 h-6" />
        </div>
        <div className="hidden md:flex col-span-12 md:col-span-2 relative justify-end">
          <img
            src={accentWhiteIcon}
            alt="Icono de Usuario"
            className="w-6 h-6 absolute bottom-0 right-0"
          />
        </div>
      </div>
    </section>
  );
};

export default PlasterboardInstallation;
