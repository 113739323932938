import React from "react";

const CallToAction = () => {
  return (
    <div className="text-center md:text-left">
      <p className="text-accent font-extralight mb-2 pb-6">
        Desde el concepto hasta la realidad, aseguramos que cada paso del
        proceso de construcción se desarrolle sin problemas. Calidad sin
        concesiones, soluciones que se adaptan a tus necesidades.
      </p>
      <a href="#callus">
        <button
          type="button"
          className="text-white bg-accent hover:bg-black focus:outline-none focus:ring-4 rounded-full text-sm px-5 py-2.5 text-center mb-2"
        >
          Contáctanos ahora
        </button>
      </a>
    </div>
  );
};

export default CallToAction;
