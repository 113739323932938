import React from "react";
import headerPhoto from "../assets/photo_header.png";

export default function Header() {
  return (
    <>
      <div className="flex flex-col justify-center text-center md:text-left md:gap-6 pb-6">
        <div className="flex flex-wrap">
          <div className="w-full md:w-auto text-4xl md:text-5xl lg:text-7xl text-accent font-normal flex justify-center items-center">
            <span>Calidad Confiable</span>
          </div>
          <div className="hidden md:block w-full md:w-auto md:ml-6">
            <img
              className="w-full h-[72px] object-cover rounded-lg rounded-br-custom-40"
              src={headerPhoto}
              alt="Galería"
            />
          </div>
        </div>
        <div className="text-4xl md:text-5xl lg:text-7xl leading-[72px] text-accent font-normal">
          <p>Soluciones Implementadas</p>
        </div>
      </div>
    </>
  );
}
