import React from "react";
import callIcon from "../assets/call_icon.svg";
import messageIcon from "../assets/message_icon.svg";
import mapIcon from "../assets/map_icon.svg";

const ContactUs = () => {
  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="callus"
    >
      <h2 className="text-3xl font-light text-accent pb-10">
        Contacta con nosotros
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
        {/* Primera columna */}
        <div className="flex flex-col col-span-12 md:col-span-8 justify-between">
          <p className="font-extralight text-accent">
            ¿Tienes alguna pregunta o necesitas más información sobre nuestros
            servicios? No dudes en contactarnos. Nuestro equipo está aquí para
            asistirte. Puedes comunicarte con nosotros a través de los
            siguientes canales.
          </p>
          <p className="font-normal text-accent pt-4 hidden md:inline-block">
            ¡Esperamos poder ayudarte pronto!
          </p>
        </div>

        {/* Segunda columna */}
        <div className="flex flex-col col-span-12 md:col-span-4 space-y-4">
          <div className="flex items-center space-x-4 sm:space-x-2">
            <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2 sm:h-10 sm:w-10">
              <img
                src={callIcon}
                alt="Icono de Teléfono"
                className="object-contain w-auto h-auto"
              />
            </div>
            <div className="text-accent">
              <h2 className="font-normal sm:text-sm">Teléfono</h2>
              <p className="font-extralight sm:text-xs">+ 34 643 75 00 27</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 sm:space-x-2">
            <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2 sm:h-10 sm:w-10">
              <img
                src={messageIcon}
                alt="Icono de Mensaje"
                className="object-contain w-auto h-auto"
              />
            </div>
            <div className="text-accent">
              <h2 className="font-normal sm:text-sm">Correo Electrónico</h2>
              <p className="font-extralight sm:text-xs">
                iliassrubio1@gmail.com
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4 sm:space-x-2">
            <div className="rounded-full bg-white border shadow-black/5 h-12 w-12 flex items-center justify-center mb-2 sm:h-10 sm:w-10">
              <img
                src={mapIcon}
                alt="Icono de Mapa"
                className="object-contain w-auto h-auto"
              />
            </div>
            <div className="text-accent">
              <h2 className="font-normal sm:text-sm">Dirección</h2>
              <p className="font-extralight sm:text-xs">Reus, Tarragona </p>
            </div>
          </div>
        </div>
      </div>
      <p className="font-normal text-accent pt-8 md:hidden inline-block">
        ¡Esperamos poder ayudarte pronto!
      </p>
    </section>
  );
};

export default ContactUs;
