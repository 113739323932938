import React from "react";
import GaleryA from "../assets/galery_a.png";
import GaleryB from "../assets/galery_b.png";
import GaleryC from "../assets/galery_c.png";
import accentIcon from "../assets/accent_icon.svg";
import accentWhiteIcon from "../assets/accent_white_icon.svg";

const BuildingConstructions = () => {
  return (
    <section
      className="Constructions mx-auto sm:px-8 p-4 pt-8 md:pt-16 max-w-7xl scroll-mt-24"
      id="buildingconstructions"
    >
      <h2 className="text-3xl font-light text-accent pb-10">
        Construcción de Edificios
      </h2>
      <div className="grid grid-cols-12 gap-4 pb-6">
        <div className="col-span-2 flex flex-col justify-between pr-6">
          <div className="flex justify-start items-start">
            <img src={accentIcon} alt="Icono de Usuario" className="w-6 h-6" />
          </div>
          <div className="flex justify-end items-end mt-auto">
            <img
              src={accentWhiteIcon}
              alt="Icono de Usuario"
              className="w-6 h-6"
            />
          </div>
        </div>
        <div className="col-span-10 bg-white font-extralight flex flex-col justify-center">
          <p>
            Nuestra empresa se especializa en la construcción de edificios,
            ofreciendo un servicio integral que abarca desde la planificación
            hasta la ejecución de proyectos residenciales, comerciales e
            industriales. Nos comprometemos a brindar un servicio al cliente
            excepcional en cada etapa del proceso, manteniendo una comunicación
            clara y transparente para garantizar la plena satisfacción del
            cliente. Con un enfoque en la seriedad y la integridad, nuestros
            clientes confían en nosotros para llevar a cabo sus proyectos con
            profesionalismo y sin sorpresas de último minuto.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 p-4">
        <div>
          <img src={GaleryA} alt="Galery A" className="rounded-2xl" />
        </div>
        <div>
          <img src={GaleryB} alt="Galery B" className="rounded-2xl" />
        </div>
        <div>
          <img src={GaleryC} alt="Galery C" className="rounded-2xl" />
        </div>
      </div>
    </section>
  );
};

export default BuildingConstructions;
