import React, { useEffect, useState } from "react";
import logo from "../assets/logo.svg";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 bg-white border-gray-200 z-50 transition-shadow duration-300 ${
        isScrolled ? "shadow-md" : ""
      }`}
    >
      <div className="flex flex-wrap items-center justify-between mx-auto p-4 max-w-7xl">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <span className="self-center whitespace-nowrap">
            <img
              className="inset-0 h-12 w-12 object-cover rounded-3xl"
              src={logo}
              alt="gallery"
            />
          </span>
        </a>
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen ? "true" : "false"}
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            <li>
              <a
                href="/#buildingconstructions"
                className="block font-extralight py-2 px-3 text-accent rounded hover:bg-accent hover:bg-opacity-75 md:hover:bg-transparent md:border-0 md:hover:text-black hover:text-white md:p-0"
                aria-current="page"
                onClick={closeMenu}
              >
                Construcción
              </a>
            </li>
            <li>
              <a
                href="/#buildingdemolition"
                className="block font-extralight py-2 px-3 text-accent rounded hover:bg-accent hover:bg-opacity-75 md:hover:bg-transparent md:border-0 md:hover:text-black hover:text-white md:p-0"
                onClick={closeMenu}
              >
                Demolición
              </a>
            </li>
            <li>
              <a
                href="/#spacerenovation"
                className="block font-extralight py-2 px-3 text-accent rounded hover:bg-accent hover:bg-opacity-75 md:hover:bg-transparent md:border-0 md:hover:text-black hover:text-white md:p-0"
                onClick={closeMenu}
              >
                Renovación
              </a>
            </li>
            <li>
              <a
                href="/#plasterboardinstallation"
                className="block font-extralight py-2 px-3 text-accent rounded hover:bg-accent hover:bg-opacity-75 md:hover:bg-transparent md:border-0 md:hover:text-black hover:text-white md:p-0"
                onClick={closeMenu}
              >
                Placa de yeso
              </a>
            </li>
            <li>
              <a
                href="/#decorationservices"
                className="block font-extralight py-2 px-3 text-accent rounded hover:bg-accent hover:bg-opacity-75 md:hover:bg-transparent md:border-0 md:hover:text-black hover:text-white md:p-0"
                onClick={closeMenu}
              >
                Decoración
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}